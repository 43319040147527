/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */
import { Box, Divider, Drawer, Grid, List, ListItem, ListItemIcon, SxProps, Theme, Tooltip, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { IT_DASHBOARD, routes } from "../constants/routes";
import { softwareVersion } from "../store/context/dashboardSlice";
import { useAppSelector } from "../store/hooks";
import { Images } from "~/components/GraphicalResources";
import { settingsSystemDashboardConsoleEnabled } from "~/store/context/settingsSlice";
import { selectUserPermssions } from "~/store/context/authSlice";
import { useMemo } from "react";

export const DRAWER_WIDTH = 60;

type Props = {};

const styles = {
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    textAlign: "center",
    "& .MuiDrawer-paper": {
      background: "#226A58",
      width: DRAWER_WIDTH,
      position: "relative",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      border: 0
    },
  },
  logo: {
    padding: "22px 0 24px"
  },
  listContainer: {
    padding: "8px 12px",
    "& a": {
      color: "#0000008a",
    },
  },
  listItem: {
    padding: "8px",
    width: "36px",
    height: "36px",
    marginX: "auto",
    marginBottom: "4px",
    borderRadius: "8px",
    "& .MuiListItemIcon-root": {
      minWidth: 0,
    },
    "& .MuiSvgIcon-root": {
      width: "20px",
      height: "20px",
      fill: "#FFFFFF"
    },
    "&:hover": {
      backgroundColor: "#FFFFFF26",
    },
    "&.Mui-selected": {
      backgroundColor: "#FFFFFF33",
    },

  },
  divider: {
    borderColor: "#EFEFEF26",
    marginTop: "8px",
    marginBottom: "12px",
  },
  versionText: {
    fontSize: "10px",
    fontWeight: 500,
    color: "white",
    opacity: 0.7
  },
  tooltip: {
    bgcolor: "#292B2F",
    marginLeft: "8px !important",
    padding: "8px",
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 600,
    color: "#F7F7F7",
  }
} satisfies Record<string, SxProps<Theme>>;

const SideDrawerNew = (props: Props) => {
  const location = useLocation();
  const version = useAppSelector(softwareVersion);
  const isDashboardConsoleEnabled = useAppSelector(settingsSystemDashboardConsoleEnabled);
  const permissionsMap = useAppSelector(selectUserPermssions);

  function canHighlightRouteIcon(pathname: string, routePath: string) {
    if (pathname === routePath) {
      return true;
    }
    return false;
  }

  const filteredRoutes = useMemo(() => {
    let prevRouteName = "";
    const filteredRoutes = routes.filter((route) => {

      // Filter out duplicate routes (espcially the duplicate separator elements)
      const currentRouteName = route.name || "";
      if(currentRouteName === prevRouteName) return false;

      // Include the routes that don't need filtering based on object type
      if (!route.objectType || route.name === "|") {
        prevRouteName = currentRouteName;
        return true;
      }

      // Filter out the routes whose object type is not in the permissions array with VIEW permission
      if (permissionsMap[route.objectType]?.VIEW) {
        prevRouteName = currentRouteName;
        return true;
      }

      return false;
    })

    // If the first element is a separator, remove it
    if(filteredRoutes[0].name === "|") filteredRoutes.shift();

    // If the last element is a separator, remove it
    if(filteredRoutes[filteredRoutes.length - 1].name === "|") filteredRoutes.pop();

    if(isDashboardConsoleEnabled) {
      return filteredRoutes
    }else{
      return filteredRoutes.filter((route) => {
        return route.path !== IT_DASHBOARD;
      })
    }
    
  }, [permissionsMap, isDashboardConsoleEnabled]);

  return (
    <Drawer variant="permanent" sx={styles.drawer}>
      <Grid height="100%" container flexDirection="column" justifyContent="space-between">
        {/* The following code is picked from the existing Side Drawer component */}
        <List sx={styles.listContainer}>
          <Grid sx={styles.logo}>
            <img src={Images.highway9Logo} alt="" />
          </Grid>
          {filteredRoutes.map((route, index) => {
            if (route.name === "|") return <Divider key={index} sx={styles.divider} />;
            return (
              <Tooltip
                title={route.name}
                aria-label={route.name}
                placement="right"
                key={route.name}
                componentsProps={
                  {
                    tooltip: {
                      sx: styles.tooltip
                    }
                  }
                }
              >
                <Link to={route.path === "/" ? "/" : route.path ?? "#"}>
                  <ListItem sx={styles.listItem} selected={canHighlightRouteIcon(location.pathname, route.path ?? "")}>
                    <ListItemIcon>
                      {route.icon}
                    </ListItemIcon>
                    {/*<ListItemText primary={''} />*/}
                  </ListItem>
                </Link>
              </Tooltip>
            );
          })}
        </List>
      </Grid>
      {version &&
        <Box>
          <Divider sx={styles.divider} />
          <Box mt={"4px"} mb={"16px"}>
            <Typography variant="caption" sx={styles.versionText}>
              {version}
            </Typography>
          </Box>
        </Box>
      }
    </Drawer>
  );
};

export default SideDrawerNew;